/* Styles in App */
.div {
  transition: all 2s;
}

.home-container{
  min-height: 50vh;
}

.main-container {
  min-height: 100vh;
  min-width: 100vw;
  background-color: #f1f2ee;
  overflow-x: hidden;
}
/* Styles in Navbar */
.logo {
  padding-left: 30px;
  display: flex;
  align-items: center;
  height: 100%;
}
.logo-container {
  padding-left: 0px;
  width: 200px;
}
.navbuttons-container {
  padding-right: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  flex-direction: row;
}
.m-navbuttons-container {
  padding-right: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
  flex-direction: column;
}
.nav-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100px;
  padding-top: 50px;
}
.m-nav-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100px;
  padding-top: 50px;
  margin-bottom: 25%;
}

/* Styles in Navbutton */
.nav-button-selected {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #507dbc;
  margin-right: 2vw;
  white-space: nowrap;
}
.nav-button {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #04080f;
  margin-right: 2vw;
  white-space: nowrap;
}
.nav-button:hover {
  cursor: pointer;
  color: #507dbc;
}

/* Styles in Footer */
.footer-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10vw;
  padding-top: 5vw;
}
.footer-text {
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  color: #507dbc;
}
.footer-logos {
  display: flex;
  justify-content: center;
}
.icons-container {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 150px;
}
.social-icon {
  height: 25px;
  width: 25px;
  background-size: cover;
  background-repeat: no-repeat;
}
.social-icon:hover {
  cursor: pointer;
}
.link {
  background-image: url("../images/linkedin.png");
}
.link:hover {
  background-image: url("../images/linkedin_dark.png");
}
.git {
  background-image: url("../images/github_square.png");
}
.git:hover {
  background-image: url("../images/git_dark.png");
}
.face {
  background-image: url("../images/faceb.png");
}
.face:hover {
  background-image: url("../images/faceb_dark.png");
}
.insta {
  background-image: url("../images/insta.png");
}
.insta:hover {
  background-image: url("../images/insta_dark.png");
}

/* Styles in About */
.bio-container {
  display: flex;
  flex-direction: row;
  padding-left: 10vw;
  padding-right: 10vw;
  justify-content: center;
}
.m-bio-container {
  display: flex;
  flex-direction: column;
  padding-left: 5vw;
  padding-right: 5vw;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.bio-image {
  background-image: url("../images/prof_pic.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 20vw;
  width: 20vw;
  margin-right: 5vw;
}
.m-bio-image {
  background-image: url("../images/prof_pic.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 40vw;
  width: 40vw;
  margin-right: 5vw;
  margin-bottom: 5vw;
}
.bio-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 55vw;
}
.m-bio-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80vw;
}
.bio-title {
  display: flex;
  justify-content: left;
  font-size: 55px;
  color: #04080f;
}
.m-bio-title {
  display: flex;
  justify-content: center;
  font-size: 40px;
  color: #04080f;
  align-items: center;
  text-align: center;
}
.bio-description {
  display: flex;
  justify-content: left;
  font-size: 30px;
  color: #04080f;
  padding-top: 10px;
}
.m-bio-description {
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: #04080f;
  padding-top: 10px;
}
.info-block-container {
  display: flex;
  flex-direction: row;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 10vh;
  padding-bottom: 10vh;
  justify-content: center;
  transition-duration: 1s;
}
.m-info-block-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vw;
  padding-bottom: 10vw;
  justify-content: center;
  transition-duration: 1s;
}
.column {
  padding-left: 1vw;
  padding-right: 1vw;
}

/* Styles in Block */
.block-container {
  margin-bottom: 2vw;
  display: flex;
  flex-direction: column;
  border-radius: 4%;
}
.m-block-container {
  margin-bottom: 8vw;
  display: flex;
  flex-direction: column;
  border-radius: 4%;
}
.block-content-container {
  padding-bottom: 2vw;
  padding-top: 2vw;
  padding-left: 2vw;
  padding-right: 2vw;
  width: 20vw;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.m-block-content-container {
  padding-bottom: 2vw;
  padding-top: 2vw;
  padding-left: 2vw;
  padding-right: 2vw;
  width: 65vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.block-title {
  text-align: center;
  font-size: 30px;
}
.block-description {
  padding-top: 0.5vw;
  text-align: center;
  font-size: 20px;
  font-style: italic;
}
.block-image-container {
  padding-top: 2vw;
  text-align: center;
}
.m-block-image-container {
  padding-top: 2vw;
  text-align: center;
}
.block-image {
  width: 15vw;
  align-items: center;
}
.m-block-image {
  width: 60%;
  align-items: center;
}
.dark {
  background-color: #507dbc;
}
.block-title.dark {
  color: #f1f2ee;
}
.block-description.dark {
  color: #f1f2ee;
}
.light {
  background-color: #bbd1ea;
}

/* Styles in Work */
.work-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Styles in WorkSection */

.work-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 5vw;
}
.m-work-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5vw;
}
.work-image-container {
  width: 15vw;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-right: 5vw;
}
.m-work-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.work-image {
  width: 15vw;
  height: 15vw;
  object-fit: cover;
  border-radius: 50%;
}
.m-work-image {
  display: flex;
  width: 35vw;
  height: 35vw;
  object-fit: cover;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 8vw;
}
.work-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
.m-work-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.work-title {
  font-weight: bold;
  font-size: 20px;
  color: #04080f;
}
.m-work-title {
  font-weight: bold;
  font-size: 30px;
  color: #04080f;
  width: 85vw;
  text-align: center;
}
.work-org {
  font-size: 20px;
  padding-bottom: 10px;
  color: #04080f;
}
.m-work-org {
  font-size: 25px;
  padding-bottom: 10px;
  color: #04080f;
  width: 85vw;
  text-align: center;
}
.work-text {
  font-size: 15px;
  color: #04080f;
  width: 50vw;
}
.m-work-text {
  font-size: 20px;
  color: #04080f;
  width: 75vw;
  text-align: center;
  margin-bottom: 6vw;
}

/* Styles in Title */
.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vw;
  margin-top: 5vw;
}
.first-line {
  background-color: #a1c6ea;
  width: 30vw;
  height: 6px;
  margin-bottom: 6px;
}
.m-first-line {
  background-color: #a1c6ea;
  width: 60vw;
  height: 6px;
  margin-bottom: 6px;
}
.second-line {
  background-color: #507dbc;
  width: 40vw;
  height: 6px;
}
.title-words {
  color: #04080f;
  font-size: 50px;
}
.m-title-words {
  font-size: 60px;
  color: #04080f;
  text-align: center;
}
.m-second-line {
  background-color: #507dbc;
  width: 70vw;
  height: 6px;
  margin-bottom: 8vw;
}

/* Styles in Interest */
.media-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.podcast-images-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.m-podcast-images-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pod-pic {
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 2vw;
  margin-left: 2vw;
}
.themoth {
  background-image: url("../images/themoth.png");
  width: 15vw;
  height: 15vw;
  border-radius: 30%;
}
.m-themoth {
  background-image: url("../images/themoth.png");
  width: 50vw;
  height: 50vw;
  border-radius: 30%;
  margin-bottom: 8vw;
}
.radio {
  background-image: url("../images/radiolab.png");
  width: 15vw;
  height: 15vw;
  border-radius: 30%;
}
.m-radio {
  background-image: url("../images/radiolab.png");
  width: 50vw;
  height: 50vw;
  border-radius: 30%;
  margin-bottom: 8vw;
}
.this-amer {
  background-image: url("../images/thisamer.png");
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
}
.m-this-amer {
  background-image: url("../images/thisamer.png");
  width: 50vw;
  height: 50vw;
  border-radius: 50%;
  margin-bottom: 8vw;
}
.books-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.books-row {
  display: flex;
  flex-direction: row;
}
.book {
  width: 20vw;
  height: 30vw;
  background-size: 100%;
  margin-right: 2vw;
  margin-left: 2vw;
  margin-bottom: 2vw;
  margin-top: 2vw;
}
.m-book {
  width: 40vw;
  height: 60vw;
  background-size: 100%;
  margin-right: 2vw;
  margin-left: 2vw;
  margin-bottom: 2vw;
  margin-top: 2vw;
}
.wolves {
  background-image: url("../images/books/wolves.png");
}
.giver {
  background-image: url("../images/books/giver.png");
}
.givesun {
  background-image: url("../images/books/givesun.png");
}
.heartbeats {
  background-image: url("../images/books/heartbeats.png");
}
.lightwecan {
  background-image: url("../images/books/lightwecan.png");
}
.littlew {
  background-image: url("../images/books/littlew.png");
}
.orphan {
  background-image: url("../images/books/orphan.png");
}
.smallthings {
  background-image: url("../images/books/smallthings.png");
}
.bookthief {
  background-image: url("../images/books/bookthief.png");
}

/* Styles in Travel */
.mapContainer {
  width: 80vw;
  height: 60vw;
  justify-content: center;
  align-content: center;
  text-align: center;
  padding-right: 0;
  margin-left: 10vw;
}
.m-mapContainer {
  width: 80vw;
  height: 150vw;
  justify-content: left;
  align-content: center;
  text-align: center;
  padding-right: 0;
  margin-left: 10vw;
}
.map-section {
  justify-content: center;
  align-content: center;
}
